import { useRouter } from 'next/router';
import QS from 'qs';

import { getPathWithDomain } from '@/utils/router.utils';

interface Options {
  keepPage?: boolean;
}

const cleanUrl = (url: string) => url.split('#')[0].split('?');

export const useCanonical = (options?: Options) => {
  const router = useRouter();
  const { keepPage = false } = options || {};
  const [url, query] = cleanUrl(router.asPath);
  const parsedQuery = QS.parse(query);
  let canonicalUrl = url;

  if (keepPage && parsedQuery.page) {
    const page = Number(parsedQuery.page) || 0;
    if (page > 1) {
      canonicalUrl += `?page=${page}`;
    }
  }
  return getPathWithDomain(canonicalUrl);
};
