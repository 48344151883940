import { NextSeo, NextSeoProps } from 'next-seo';
import React from 'react';

import { useCanonical } from '@/hooks/useCanonical';

export const Seo = (props: NextSeoProps & { keepPage?: boolean }) => {
  const { keepPage = false, nofollow = false, noindex = false, ...restProps } = props;
  const canonical = useCanonical({ keepPage });
  const shouldAddCanonical = !nofollow && !noindex;
  return (
    <NextSeo
      nofollow={nofollow}
      noindex={noindex}
      canonical={shouldAddCanonical ? canonical : undefined}
      {...restProps}
    />
  );
};
