import { cva } from 'class-variance-authority';
import React from 'react';

import { Icon, Icons } from '@/components/_atoms/Icon';
import { cn } from '@/utils/styles.utils';

type Props = {
  id?: string;
  label?: string;
  value: string | undefined;
  disabled?: boolean;
  variant?: 'outline' | 'solid' | 'empty';
  size?: 's' | 'm' | 'l';
  icon?: Icons;
  children?: React.ReactElement;
  isActive?: boolean;
  isFocus?: boolean;
  isError?: boolean;
  multiline?: boolean;
  required?: boolean;
  leftIcon?: Icons;
  select?: boolean;
};

export const inputVariants = cva(
  [
    'inline-flex h-full w-full rounded-lg border border-transparent bg-transparent text-text-grey-dark outline-none transition-colors',
    'data-[error=false]:focus:border-orange-500 data-[error=false]:focus:bg-white data-[error=false]:focus:ring-2 data-[error=false]:focus:ring-[rgba(255,191,0,0.2)]',
    'data-[error=true]:bg-red-100 data-[error=true]:hover:bg-red-100',
    'disabled:pointer-events-none disabled:bg-grey-200 disabled:text-text-grey-light',
  ],

  {
    variants: {
      variant: {
        empty: 'hover:bg-grey-300',
        outline: 'border-grey-500 hover:border-grey-600 hover:bg-grey-300',
        solid: 'bg-grey-200 hover:bg-grey-300',
      },
      size: {
        s: 'pb-[6px] pt-[6px]',
        m: 'pb-[14px] pt-[14px]',
        l: 'pb-[8px] pt-[28px]',
      },
    },
    defaultVariants: {
      size: 'l',
      variant: 'solid',
    },
  },
);

export const InputBox = (props: Props) => {
  const {
    isActive = false,
    children,
    icon,
    label = null,
    value,
    variant = 'solid',
    size = 'l',
    isError = false,
    multiline = false,
    required = false,
    leftIcon,
    select,
    id,
  } = props;

  const LabelComponent = (
    <label
      htmlFor={id}
      className={cn(
        'pointer-events-none absolute left-4 top-[18px] text-body-m text-text-grey-standard',
        'peer-disabled:text-text-grey-light',
        'origin-left transition-all',
        {
          'left-12': leftIcon,
          'text-text-red peer-focus:text-text-red': isError,
          'translate-y-[-10px] scale-[0.85]': isActive,
          'peer-focus:translate-y-[-10px] peer-focus:scale-[0.85] peer-focus:text-text-grey-semi': !select,
        },
      )}
    >
      {label}
      {required && <span className="ml-0.5 text-body-m text-text-red">{'*'}</span>}
    </label>
  );

  return (
    <div
      className={cn('relative inline-flex w-full', {
        'h-[32px]': size === 's',
        'h-[48px]': size === 'm',
        'h-[56px]': size === 'l',
        'h-auto': multiline,
      })}
    >
      {leftIcon && <Icon name="search" color="grey-600" className="pointer-events-none absolute left-4 self-center" />}

      {children &&
        React.cloneElement(children, {
          'data-error': isError,
          className: cn(
            inputVariants({
              size,
              variant,
            }),
            'peer pl-4 pr-4',
            {
              'pl-12': leftIcon,
              'pr-4': icon,
              'h-auto': multiline,
            },
            children.props.className,
          ),
        })}

      {size === 'l' ? LabelComponent : null}

      {size === 'm' && label && !value ? LabelComponent : null}

      {size === 's' && label && !value ? LabelComponent : null}

      {icon && (
        <Icon
          name={icon}
          size={12}
          className="peer:fill-grey-500 pointer-events-none absolute right-4 self-center fill-grey-800"
        />
      )}
    </div>
  );
};
